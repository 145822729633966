import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const TechnologyBarChart = React.memo((props)=>{
    const { classes, colors, technologyItems, technologyStatuses } = props
    const datasets = technologyStatuses.map(status=>technologyItems.map(item=>item[status.id]))
    const labels = technologyStatuses.map(status=>status.name);
    const chartData = {
      labels: technologyItems.map(n=>n.name),
      datasets: datasets.map((data,i)=>{
          return {
            label: labels[i],
            backgroundColor: colors[i],
            data: data,
        }})
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: "Technology",
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false
                }   
            }]
        },
        tooltips: {
            mode : 'label',
        },
    };
    return(
        <Bar
        //   width={380}
          height={200}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(TechnologyBarChart);