import React, { useState, useEffect } from 'react';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import 'typeface-roboto';
import BottomBar from './BottomBar';
import NavigationBar from './NavigationBar';
import StartPage from './StartPage';
import CreateStatusReportPage from './CreateStatusReportPage/CreateStatusReportPage';
import ReportSubmittedPage from './ReportSubmittedPage';
import ReportsPage from './ReportsPage';
import HelpPage from './HelpPage';
import ReportDetailsPage from './ReportDetailsPage/ReportDetailsPage';
import EventsPage from './EventsPage';
import AdminRoute from './AdminRoute';
import EventDetailsPage from './EventDetails/EventDetailsPage';
// import EventTableAggregatedPage from './EventDetails/EventTableAggregatedPage';
// import TermsAndConditionsPage from './TermsAndConditionsPage';
// import PrivacyPolicyPage from './PrivacyPolicyPage';
import AuthenticatingPage from './AuthenticatingPage';
import LoggedOutPage from './LoggedOutPage';
import HospitalsMapPage from './HospitalsMapPage';
import LoadingPage from './LoadingPage';
import SettingsPage from './SettingsPage';
import CreateStrikeReportPage from './StrikeDashboard/CreateStrikeReportPage';
import StrikeDashboardPage from './StrikeDashboard/StrikeDashboardPage';
import { loginAuth, handleAxiosError, getAPIUrl } from './AppUtils';

import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faFileDownload, faCaretDown, faAsterisk, faSignOutAlt, faTrashAlt, faEdit, faCog, faUsers } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInitialData, setStrikeInitialData } from './actions';

import { pdfjs } from 'react-pdf';
import { Chart } from 'react-chartjs-2';
import StrikeReportSubmittedPage from './StrikeDashboard/StrikeReportSubmittedPage';
import StrikeEventsPage from './StrikeDashboard/StrikeEventsPage';
import StrikeReportsPage from './StrikeDashboard/StrikeReportsPage';
import StrikeReportDetailsPage from './StrikeDashboard/StrikeReportDetailsPage';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Chart.defaults.global.plugins.datalabels.display = false

library.add(faLinkedin);
library.add(faFileDownload);
library.add(faCaretDown);
library.add(faAsterisk);
library.add(faSignOutAlt);
library.add(faTrashAlt);
library.add(faEdit);
library.add(faCog);
library.add(faUsers);

const darkBlue = '#191F2D';
const lightBlue = '#51c8f6';
const toolbarHeight = 90;
const textColor = "#191f2e";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: darkBlue,
    },
    secondary: {
      main: lightBlue,
      contrastText: "#FFF",
    },
    text: {
      primary: textColor,
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    textTransform: "none",
    button: {
      textTransform: "none",
    },
  },
  mixins: {
    toolbar: {
      minHeight: toolbarHeight,
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      }
    },
    MuiInputBase: {
      root: {
        lineHeight: '1.25em',
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      }
    }
  }
});
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    minHeight: '100vh',
    zIndex: 1,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    // marginTop: 0,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    overflowY: "hidden",
    margin: 0,
  },
});

var token = localStorage.getItem('intelhsApiToken')
var initialUser = {
  authenticated:false,
  id: undefined,
  type: undefined,
  first_name: undefined,
}
if(token){
  setAxiosHeaders(token);
  const tokenPayload = getTokenPayload(token);
  if(tokenPayload){
    initialUser = getUserFromPayload(tokenPayload);
  }
}
const initialAdminView = initialUser.type>0
function getTokenPayload(token){
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64)).payload;
}
function getUserFromPayload(tokenPayload){
  return {
    authenticated: true,
    id: tokenPayload.id,
    type: parseInt(tokenPayload.is_admin),
    first_name: tokenPayload.first_name,
  };
}
function setAxiosHeaders(token){
  if(token){
      axios.defaults.headers = {
          Authorization: 'Bearer ' + token
      }
  }
}
function App(props) {
  const { classes, setInitialData, setStrikeInitialData } = props;
  const [departmentInfo, setDepartmentInfo] = useState(undefined);
  const [adminView, setAdminView] = useState(initialAdminView);
  const [user, setUser] = useState(initialUser);
  const [logo, setLogo] = useState(undefined);
  const [orgSettings, setOrgSettings] = useState({});
  const [fetchedOrgSettings, setFetchedOrgSettings] = useState(false);
  function refreshToken(){
      // var base64Url = token.split('.')[1];
      // var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      // var expDate = JSON.parse(window.atob(base64)).exp 
      // var currDate = Date.now()/1000;
      //less than a week till expiry
      // if(expDate < currDate + 604800){
      axios.get(getAPIUrl()+'/refreshToken')
      .then(response => {
          // console.log(response)
          localStorage.setItem('intelhsApiToken', response.data)
          setAxiosHeaders(response.data);
          const tokenPayload = getTokenPayload(response.data);
          setUser(getUserFromPayload(tokenPayload));
      })
      .catch(error=>handleAxiosError(error));
      // }
  }
  useEffect(() => {
    if(user.authenticated) {
      getLogo();
      getOrgSettings();
      getInitialData();
      refreshToken();
    }
    else if(window.location.href.includes('/cognito?code=')) {
      authenticateCognitoCode();
    }
    else if(window.location.href.includes('/saml?otp=')){
      authenticateSAMLOtp();
    }
    else if(window.location.href.includes('/loggedout')){
      //
    }
    else{
      loginAuth()
    }
  },[]);
  function authenticateCognitoCode(){
    const code = window.location.href.split('code=')[1]
    axios.post(getAPIUrl() + '/authenticateCognitoCode', { 'code': code, 'redirect_uri': window.location.origin+'/cognito' })
      .then(response => handleAuthenticationResponse(response))
      .catch(error => {})
  }
  function authenticateSAMLOtp(){
    const otp = window.location.href.split('otp=')[1]
    axios.post(getAPIUrl() + '/authenticateSAMLOTP', { 'otp': otp })
      .then(response => handleAuthenticationResponse(response))
      .catch(error => {})
  }
  function handleAuthenticationResponse(response){
    if (response.data && response.data['status'] === 'success') {
      var token = response.data['api_token']
      localStorage.setItem('intelhsApiToken', token)
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var tokenPayload = JSON.parse(window.atob(base64)).payload;
      setAxiosHeaders(token)
      getLogo();
      getOrgSettings();
      getInitialData();
      setAdminView(tokenPayload.is_admin>0);
      setUser({
        authenticated: true,
        id: tokenPayload.id,
        type: parseInt(tokenPayload.is_admin),
        first_name: tokenPayload.first_name,
      });
    }
    else {
      // console.log(response)
      loginAuth()
    }
  }
  function getLogo() {
      axios.get(getAPIUrl()+'/orgLogo')
      .then(response => {
          import(`./images/${response.data}`)
          .then(img=>{
              setLogo(img.default);
          })
          .catch(error=>{});
      })
      .catch(error=>handleAxiosError(error));
  }
  function getOrgSettings() {
      setFetchedOrgSettings(false);
      axios.get(getAPIUrl()+'/orgSettings')
      .then(response => {
        setOrgSettings(response.data);
        if(response.data.strikeDashboard){
          getStrikeInitialData();
        }
        setFetchedOrgSettings(true);
      })
      .catch(error=>handleAxiosError(error));
  }
  function getStrikeInitialData(){
    axios.get(getAPIUrl()+'/strikeInitialData')
    .then(response => {
      setStrikeInitialData(response.data);
    })
    .catch(error=>handleAxiosError(error));
  }
  function getInitialData(){
    axios.get(getAPIUrl()+'/initialData')
    .then(response => {
      setInitialData(response.data);
    })
    .catch(error=>handleAxiosError(error));
  }
  
  // console.log(user);
  // console.log(adminView);
  // console.log(window.location.origin);
  if(!user.authenticated){
    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path='/loggedout'
            render={(props) => <LoggedOutPage {...props}
              
            />}
          />
          <Route exact path='*'
            render={(props) => 
              <AuthenticatingPage/>
            }
          />
        </Switch>
      </MuiThemeProvider>
    )
  }
  if(!fetchedOrgSettings){
    return(
      <MuiThemeProvider theme={theme}>
        <LoadingPage/>
      </MuiThemeProvider>
    )
  }
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.appFrame}>
          <NavigationBar
            user={user}
            adminView={adminView}
            setAdminView={setAdminView}
            history={props.history}
            setUser={setUser}
            orgSettings={orgSettings}
          />
          <div className={classes.content}>
            <Switch>
              <Route exact path='/start'
                render={(props) => <StartPage {...props}
                  departmentInfo={departmentInfo}
                  setDepartmentInfo={setDepartmentInfo}
                  adminView={adminView}
                  setAdminView={setAdminView}
                  logo={logo}
                  orgSettings={orgSettings}
                />}
              />
              <Route exact path='/emstatrep'
                render={(props) =>
                  departmentInfo ?
                    <CreateStatusReportPage {...props}
                      departmentInfo={departmentInfo}
                      setDepartmentInfo={setDepartmentInfo}
                      logo={logo}
                      orgSettings={orgSettings}
                    />
                    : <Redirect to='/start' />
                }
              />
              {orgSettings.strikeDashboard?
                <Route exact path='/createStrikeReport'
                  render={(props) =>
                    departmentInfo ?
                      <CreateStrikeReportPage {...props}
                        departmentInfo={departmentInfo}
                        setDepartmentInfo={setDepartmentInfo}
                        logo={logo}
                        orgSettings={orgSettings}
                      />
                      : <Redirect to='/start' />
                  }
                />:undefined
              }
              {orgSettings.strikeDashboard?
                <Route exact path='/strikeSubmitted'
                  render={(props) => <StrikeReportSubmittedPage {...props}
                    adminView={adminView}
                    setAdminView={setAdminView}
                  />}
                />:undefined
              }
              {orgSettings.strikeDashboard?
                <Route exact path='/strikeReports'
                  render={(props) => <StrikeReportsPage {...props}
                    adminView={adminView}
                    setAdminView={setAdminView}
                  />}
                />:undefined
              }
              {orgSettings.strikeDashboard?
                <Route exact path='/strikeReportDetails/:id(\d+)'
                  render={(props) => <StrikeReportDetailsPage {...props}
                    logo={logo}
                    adminView={adminView}
                    setAdminView={setAdminView}
                  />}
                />:undefined
              }
              <Route exact path='/reportResponse'
                render={(props) => <ReportSubmittedPage {...props}
                  adminView={adminView}
                  setAdminView={setAdminView}
                />}
              />
              <Route exact path='/report-list'
                render={(props) => <ReportsPage {...props}
                  adminView={adminView}
                  user={user}
                />}
              />
              <Route exact path='/reportDetails/:id(\d+)'
                render={(props) => <ReportDetailsPage {...props}
                  logo={logo}
                  orgSettings={orgSettings}
                />}
              />
              <Route exact path='/help'
                render={(props) => <HelpPage {...props}
                  adminView={adminView}
                  setAdminView={setAdminView}
                />}
              />
              <Route exact path='/locations'
                render={(props) => <HospitalsMapPage {...props}
                  adminView={adminView}
                  setAdminView={setAdminView}
                />}
              />
              {/* <Route exact path='/privacy_policy'
                render={(props) => <PrivacyPolicyPage {...props}
                  adminView={adminView}
                  setAdminView={setAdminView}
                />}
              />
              <Route exact path='/termsandconditions'
                render={(props) => <TermsAndConditionsPage {...props}
                  adminView={adminView}
                  setAdminView={setAdminView}
                />}
              /> */}
              {/* <AdminRoute path='/event'
                user={user}
                component={EventDetailsPage}
                componentProps={{
                  adminView: adminView,
                  setAdminView: setAdminView,
                  user: user,
                }}
              /> */}
              {orgSettings.strikeDashboard?
              <AdminRoute exact path='/strikeDashboard/:id(\d+)?'
                user={user}
                component={StrikeDashboardPage}
                componentProps={{
                  adminView: adminView,
                  setAdminView: setAdminView,
                  user: user,
                }}
              />:undefined}
              {orgSettings.strikeDashboard?
              <AdminRoute path='/strikeEvents'
                user={user}
                component={StrikeEventsPage}
                componentProps={{
                  adminView: adminView,
                  setAdminView: setAdminView,
                  user: user,
                }}
              />:undefined}
              <AdminRoute path='/event/:id(\d+)?'
                user={user}
                component={EventDetailsPage}
                componentProps={{
                  adminView: adminView,
                  setAdminView: setAdminView,
                  user: user,
                }}
              />
              <AdminRoute exact path='/eventsSummary'
                user={user}
                component={EventsPage}
                componentProps={{
                  adminView: adminView,
                  setAdminView: setAdminView,
                  orgSettings:orgSettings
                }}
              />
              <Route exact path='/reportDetails/:id(\d+)'
                render={(props) => <ReportDetailsPage {...props}
                  logo={logo}
                  orgSettings={orgSettings}
                />}
              />
              <Route exact path='/settings'
                render={(props) => (
                    user.type===3?
                    <SettingsPage {...props}/> :
                    <Redirect to='/unauthorized'/>
                )} 
              />
              <Route path="*"
                render={(props) => user.type>0 ?
                  <Redirect to="/event" />
                  : <Redirect to="/start" />
                }
              />
            </Switch>
          </div>
          <BottomBar />
        </div>
      </MuiThemeProvider>
    </div>
  );
}

const mapDispatchToProps = function (dispatch) {
return bindActionCreators({
  setInitialData: setInitialData,
  setStrikeInitialData: setStrikeInitialData,
}, dispatch)
}
export default withStyles(styles)(withRouter(connect(null,mapDispatchToProps)(App)));
