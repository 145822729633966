import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const styles = theme => ({
});

const TRAINBarChart = React.memo((props)=>{
    const { classes, TRAINTypes, TRAINCategories, TRAINData } = props
    const colors = ["#3AC7FF","#009D00","#fdd835","#f57c00","#e00e3c"]
    const labels = TRAINCategories.map(n=>n.name);
    const chartData = {
      labels: TRAINTypes.map(n=>n.name).concat(["Total"]),
      datasets: TRAINCategories.map((category,i)=>{
          return {
            label: labels[i],
            backgroundColor: colors[i],
            data: TRAINTypes.map(type=>TRAINData[type.id][category.id]).concat([TRAINTypes.reduce((sum,type)=>{
                sum += TRAINData[type.id][category.id];
                return sum;
            },0)]),
        }})
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: "Triage Resource Allocation for Inpatients",
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:12 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                },
                ticks: {
                    fontSize: 12
                },
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: false,
                    display:false
                },
                ticks: {
                    display: false
                }
            }],
        },
        tooltips: {
            enabled: false,
            // mode : 'label',
        },
        hover: {mode: null},
        plugins: {
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'middle',
                // function(context) {
                //     console.log(context.dataset.data[context.dataIndex]/context.chart.scales['y-axis-0'].max)
                //     return context.dataset.data[context.dataIndex]/context.chart.scales['y-axis-0'].max > 0.05?'middle':'top';
                // },
                align: function(context) {
                    // console.log(context)
                    // console.log(context.dataset.data[context.dataIndex]/context.chart.scales['y-axis-0'].max)
                    return context.dataset.data[context.dataIndex]/context.chart.scales['y-axis-0'].max > 0.075?'middle':'top';
                },
                font: {
                    // weight: 'bold'
                },
            }
        },
    };
    return(
        <Bar
          width={500}
          height={150}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(TRAINBarChart);