import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, CircularProgress, Tooltip, FormControl, IconButton } from '@material-ui/core'
import { useCheckbox, useFormInput } from '../CustomHooks';
import TimeDisplay from '../TimeDisplay';
import axios from 'axios';
import CreateStaffingTable from './CreateStaffingTable';
import CreateAvailableBedsTable from './CreateAvailableBedsTable';
import CreateTechnologyStatusTable from './CreateTechnologyStatusTable';
import CreateResourceStatusTable from './CreateResourceStatusTable';
import CreateTRAINTable from './CreateTRAINTable';
import CustomizedTextField from '../CustomizedTextField'
import { handleAxiosError, getAPIUrl } from '../AppUtils';
import { format } from 'date-fns';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';

const styles = theme => ({
    page: {
        width: "100%",
    },
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
    },
    departmentInfoBox: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 2,
    },
    urgentNeedBox: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "red",
        padding: 8,
    },
    button: {
        marginTop: 10,
    },
    formControl: {
        minWidth: 200,
        backgroundColor: "#FFF",
        // borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e2e2e2"
    },
    select: {
        fontFamily: "'Open Sans', sans-serif",
        color: "#191f2e",
    },
    input: {
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
    },
    errorMessage: {
        color: 'red',
    },
    submitErrorMessage: {
        color: 'red',
        marginTop: 10,
        marginLeft: 10,
    },
    formControl: {
        minWidth: 130,
    },
    select: {
        color: "#555555",
        fontSize: 16,
    },
});

function CreateStatusReportPage(props) {
    const { classes, departmentInfo, logo, orgSettings, initialDetails } = props;
    const source = axios.CancelToken.source();

    const urgentNeed = useCheckbox(false);
    const bedsNotApplicable = useCheckbox(false);
    const trainNotApplicable = useCheckbox(false);
    const noImpact = useCheckbox(false);
    const noProblems = useCheckbox(false);

    const [orgName, setOrgName] = useState("");

    const [staffingEntries,setStaffingEntries] = useState([{
        title: -1, 
        present: "",
        available: "",
        otherTitle: "", 
    }]);
    const [staffTitles, setStaffTitles] = useState([]);
    const [bedStatuses, setBedStatuses] = useState([]);
    const [bedTypes, setBedTypes] = useState([]);
    const [bedAvailability, setBedAvailability] = useState(undefined);
    const [NABedAvailability, setNABedAvailability] = useState(undefined);
    const [technologyItems, setTechnologyItems] = useState([]);
    const [technologyStatuses, setTechnologyStatuses] = useState([]);
    const [technologyItemStatus, setTechnologyItemStatus] = useState(undefined);
    const [resourceItems, setResourceItems] = useState([]);
    const [resourceItemStatus, setResourceItemStatus] = useState(undefined);
    const [totalPatientCount, setTotalPatientCount] = useState(0);
    const [TRAINDetails, setTRAINDetails] = useState({types:[],categories:[]});
    const [TRAINData, setTRAINData] = useState(undefined);
    const [NATRAINData, setNATRAINData] = useState(undefined);
    const eventImpactText = useFormInput("");
    const problemsProgressText = useFormInput("");

    //error messages
    const [invalidReport, setInvalidReport] = useState(false);
    const [staffingTitleRequired, setStaffingTitleRequired] = useState(false);
    const [staffingPresentRequired, setStaffingPresentRequired] = useState(false);
    const [staffingAvailableRequired, setStaffingAvailableRequired] = useState(false);
    const [staffingOtherTitleRequired, setStaffingOtherTitleRequired] = useState(false);
    const [staffingAvailableGreaterThanPresent, setStaffingAvailableGreaterThanPresent] = useState(false);
    const [bedPatientAwaitGreaterThanPresent, setBedPatientAwaitGreaterThanPresent] = useState(false);
    const [resourceAvailableGreaterThanOnHand, setResourceAvailableGreaterThanOnHand] = useState(false);

    const [openEvents, setOpenEvents] = useState(undefined);
    const [noEvent, setNoEvent] = useState(false);
    const selectedEvent = useFormInput("");

    const [submittingReport, setSubmittingReport] = useState(false);
    //mount, unmount
    useEffect(() => {
        getOrgName();
        getStaffTitles();
        getBedTypes();
        getBedStatuses();
        getTechnologyItems();
        getTechnologyStatuses();
        getResourceItems();
        getOpenEvents();
        if(orgSettings && orgSettings.TRAIN){
            getTRAINDetails();
        }
        if(initialDetails){
            eventImpactText.onChange({target:{value:initialDetails.eventImpactText}});
            problemsProgressText.onChange({target:{value:initialDetails.problemsProgressText}});
            noImpact.onChange({target:{checked:initialDetails.noImpact}})
            noProblems.onChange({target:{checked:initialDetails.noProblems}})
            urgentNeed.onChange({target:{checked:initialDetails.urgentNeed}})
            selectedEvent.onChange({target:{value:initialDetails.eventId}})
            bedsNotApplicable.onChange({target:{checked:initialDetails.bedsNotApplicable}})
            setStaffingEntries(initialDetails.staffing.map(n=>{
                return {
                    title: n.title_id, 
                    present: n.present, 
                    available: n.available,
                    otherTitle: n.title_id===14?n.title:"", 
                    //Hard Coded title.id 14 = Other
                }
            }));
        }
        return () => {source.cancel("unmount");}
    }, []);
    
    useEffect(()=>{
        if(bedTypes.length>0 && bedStatuses.length>0){
            const bedDefault = 
                bedTypes.reduce((obj,type)=>{
                    obj[type.id] = bedStatuses.reduce((obj2,status)=>{
                        obj2[status.id] = status.categories.reduce((obj3,category)=>{
                            obj3[category.id] = initialDetails?
                                initialDetails.bedAvailability[type.id][status.id][category.id]
                                :0
                            return obj3;
                            },{})
                        return obj2;
                        },{})
                    return obj;
                },{})
            setBedAvailability(bedDefault);
            setNABedAvailability(bedDefault);
        }
    },[bedTypes,bedStatuses])
    //Set total patient count
    useEffect(()=>{
        let patientSum = 0;
        if(bedAvailability){
            Object.values(bedAvailability).forEach(type=>{
                //Hard Coded Right Now status.id 1 = Patients Present
                Object.values(type[1]).forEach(categoryVal=>{
                    patientSum+=categoryVal;
                })
            })
        }
        setTotalPatientCount(patientSum);
    },[bedAvailability])
    useEffect(()=>{
        if(technologyItems.length>0 && technologyStatuses.length>0){
            setTechnologyItemStatus(
                technologyItems.reduce((obj,item)=>{
                    obj[item.id] = initialDetails?
                        initialDetails.technologyItemStatusIds[item.id]:technologyStatuses[0].id;
                    return obj;
                },{})
            )
        }
    },[technologyItems,technologyStatuses])
    
    useEffect(()=>{
        if(resourceItems.length>0){
            setResourceItemStatus(
                resourceItems.reduce((obj,item)=>{
                    obj[item.id] = initialDetails?
                        initialDetails.resourceItemStatus[item.id]
                        :{onHand: 0, availableForDeployment: 0};
                    return obj
                },{})
            )
        }
    },[resourceItems])

    useEffect(()=>{
        if(TRAINDetails.types.length>0 && TRAINDetails.categories.length>0){
            const defaultTRAINData = 
                TRAINDetails.types.reduce((obj,type)=>{
                    obj[type.id] = TRAINDetails.categories.reduce((obj2,category)=>{
                        if(initialDetails){
                            obj2[category.id] = initialDetails.TRAINData[type.id][category.id];
                            obj2.specializedNeeds = initialDetails.TRAINData[type.id].specializedNeeds;
                        }
                        else{
                            obj2[category.id] = 0;
                            obj2.specializedNeeds = '';
                        }
                        return obj2;
                        },{})
                    return obj;
                },{});
            setTRAINData(defaultTRAINData);
            setNATRAINData(defaultTRAINData);
        }
    },[TRAINDetails])

    useEffect(()=>{
        if(openEvents && openEvents.length > 0 && !selectedEvent.value){
            selectedEvent.onChange({target:{value:openEvents[0].id}})
        }
    },[openEvents])

    function getTRAINDetails(){
        axios.get(getAPIUrl()+'/TRAINDetails',{cancelToken: source.token})
        .then(response => {
            setTRAINDetails(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getOrgName() {
        axios.get(getAPIUrl()+'/orgName',{cancelToken: source.token})
        .then(response => {
            setOrgName(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getStaffTitles() {
        axios.get(getAPIUrl()+'/staffTitles',{cancelToken: source.token})
        .then(response => {
            setStaffTitles(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getBedTypes() {
        axios.get(getAPIUrl()+'/bedTypes',{cancelToken: source.token})
        .then(response => {
            setBedTypes(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getBedStatuses() {
        axios.get(getAPIUrl()+'/bedStatusesAndCategories',{cancelToken: source.token})
        .then(response => {
            setBedStatuses(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getTechnologyItems() {
        axios.get(getAPIUrl()+'/technologyItems',{cancelToken: source.token})
        .then(response => {
            setTechnologyItems(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getTechnologyStatuses() {
        axios.get(getAPIUrl()+'/technologyStatuses',{cancelToken: source.token})
        .then(response => {
            setTechnologyStatuses(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getResourceItems() {
        axios.get(getAPIUrl()+'/resourceItems',{cancelToken: source.token})
        .then(response => {
            setResourceItems(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function getOpenEvents() {
        axios.get(getAPIUrl()+'/openEvents',{cancelToken: source.token})
        .then(response => {
            setOpenEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    function addStaffingRow(){
        let newArr = staffingEntries.concat({
            title: -1, 
            present: "", 
            available: "",
            otherTitle: "", 
        });
        setStaffingEntries(newArr);
    }
    function submitReport(){
        setNoEvent(false);
        if(openEvents && openEvents.length === 0){
            setNoEvent(true);
            return;
        }
        setInvalidReport(false);
        if(!allFieldsValid()){
            setInvalidReport(true);
        }
        else{
            let reportData = {
                eventId: selectedEvent.value,
                departmentInfo,
                urgentNeed:urgentNeed.checked,
                staffingEntries,
                totalPatientCount,
                bedsNotApplicable:bedsNotApplicable.checked,
                bedAvailability:bedsNotApplicable.checked?NABedAvailability:bedAvailability,
                technologyItemStatus,
                resourceItemStatus,
                noImpact:noImpact.checked,
                eventImpactText:eventImpactText.value,
                noProblems:noProblems.checked,
                problemsProgressText:problemsProgressText.value,
                userCurrentTime: format(new Date(),'MMM dd, yyyy hh:mm a')
            }
            if(orgSettings.TRAIN){
                reportData.TRAIN = trainNotApplicable.checked?NATRAINData:TRAINData;
                reportData.trainNotApplicable = trainNotApplicable.checked;
            }
            setSubmittingReport(true);
            axios.post(getAPIUrl()+'/submitReport',{...reportData},{cancelToken: source.token})
            .then(response => {
                // console.log(response)
                setSubmittingReport(false);
                props.setDepartmentInfo(undefined);
                props.history.push("/reportResponse");
            })
            .catch(error => {
                // console.log(error)
                if(handleAxiosError(error)!=='cancel'){
                    setSubmittingReport(false);
                }
            });
        }
    }
    function allFieldsValid(){
        let valid = true;
        if(!staffingTableValid()){
            valid = false;
        }
        if(!availableBedsTableValid()){
            valid = false;
        }
        if(!resourceTableValid()){
            valid = false;
        }
        return valid;
    }
    function staffingTableValid(){
        let valid = true;
        setStaffingTitleRequired(false);
        setStaffingPresentRequired(false);
        setStaffingAvailableRequired(false);
        setStaffingOtherTitleRequired(false);
        setStaffingAvailableGreaterThanPresent(false);
        staffingEntries.forEach(entry=>{
            if(entry.title===-1){
                valid = false;
                setStaffingTitleRequired(true);
            }
            if(entry.present===""){
                valid = false;
                setStaffingPresentRequired(true);
            }
            if(entry.available===""){
                valid = false;
                setStaffingAvailableRequired(true);
            }
            else{
                if(entry.present!=="" && entry.present<entry.available){
                    valid = false;
                    setStaffingAvailableGreaterThanPresent(true);
                }
            }
            //Hard Coded Other=14
            if(entry.title===14 && entry.otherTitle===""){
                valid = false;
                setStaffingOtherTitleRequired(true);
            }
        })
        return valid;
    }
    function availableBedsTableValid(){
        let valid = true;
        setBedPatientAwaitGreaterThanPresent(false);
        Object.values(bedAvailability).forEach(bedType=>{
            //Hard Coded 1 = Patients Present, 2 = Awaiting Discharge
            Object.entries(bedType[1]).forEach(([categoryId,categoryValue])=>{
                if(bedType[2][categoryId]>categoryValue){
                    valid=false;
                    setBedPatientAwaitGreaterThanPresent(true);
                }
            })
        })
        return valid;
    }
    function resourceTableValid(){
        let valid = true;
        setResourceAvailableGreaterThanOnHand(false);
        Object.values(resourceItemStatus).forEach(resourceItem=>{
            if(resourceItem.availableForDeployment > resourceItem.onHand){
                valid = false;
                setResourceAvailableGreaterThanOnHand(true);
            }
        })
        return valid;
    }
    function getSectionNumber(num){
        let sectionNum = num;
        if(orgSettings.TRAIN){
            if(num >= 4){
                sectionNum += 1;
            }
        }
        return sectionNum;
    }
    return (
        <div className={classes.page}>
            <Grid container justify='center' className={classes.gridContainer}>
                <Grid item md={4}>
                    <img className={classes.companyLogo}
                        src={logo}
                        alt=''
                    />
                </Grid>
                <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                    <Typography className={classes.title} variant="h3">Status Report (STATREP)</Typography>
                    <TimeDisplay/>
                </Grid>
                <Grid item md={4}>
                    {openEvents?
                        openEvents.length > 0?
                        <Grid style={{height:"100%"}} container alignItems="center" justify="flex-end">
                            <Typography variant="h6" style={{marginRight:15}}>Event:</Typography>
                            <FormControl className={classes.formControl}>
                                <select
                                    className={classes.select}
                                    {...selectedEvent}
                                >
                                    {openEvents.map(n=>
                                        <option key={n.id} value={n.id}>{n.name}</option>
                                    )}
                                </select>
                            </FormControl>
                        </Grid>:
                        <Grid style={{height:"100%"}} container alignItems="center" justify="flex-end">
                            <Typography variant="h6">No Open Events</Typography>
                            <IconButton className={classes.iconButton}
                                onClick={getOpenEvents}
                            >
                                {/* <FontAwesomeIcon size="xs" icon={["fa","file-download"]}/> */}
                                <RefreshIcon/>
                            </IconButton>
                        </Grid>
                        :undefined
                    }
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item sm={8}>
                    <div className={classes.departmentInfoBox}>
                        <Typography style={{fontWeight:"bold",fontSize:16}}>{orgName}</Typography>
                        <br/>
                        <Typography>Comprehensive Emergency Management Plan</Typography>
                        {departmentInfo.labels.map((n,i)=>{
                            if(i%2===0){
                                return(
                                    <Grid container justify='space-between' key={i}>
                                        <Typography style={{fontWeight:"bold"}}>{n}</Typography>
                                        {<Typography style={{fontWeight:"bold"}}>{departmentInfo.labels[i+1]?departmentInfo.labels[i+1]:''}</Typography>}
                                    </Grid>
                                )
                            }
                        })}
                    </div>
                </Grid>
                <Grid item sm={2}/>
                <Grid item sm={2}>
                    <div className={classes.urgentNeedBox}>
                        <Typography style={{fontSize:20}}>
                            <input type="checkbox" {...urgentNeed}/>
                            Urgent Need
                        </Typography>
                        <Typography style={{fontSize: 12}}>(Check for Life Safety Issue)</Typography>
                        <Typography style={{fontSize: 9, fontWeight:"bold"}}>*Only check this box if you are having an Urgent Need in your area, otherwise leave blank</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">1. Staffing</Typography>
                    <CreateStaffingTable 
                        staffingEntries={staffingEntries} 
                        staffTitles={staffTitles}
                        setStaffingEntries={setStaffingEntries}
                    />
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>addStaffingRow()}
                    >
                        Add Row
                    </Button>
                    {staffingTitleRequired?<Typography className={classes.errorMessage}>Title field is required</Typography>:undefined}
                    {staffingPresentRequired?<Typography className={classes.errorMessage}>Number Present field is required</Typography>:undefined}
                    {staffingAvailableRequired?<Typography className={classes.errorMessage}>Available to Labor Pool field is required</Typography>:undefined}
                    {staffingOtherTitleRequired?<Typography className={classes.errorMessage}>Title field(Other) is required</Typography>:undefined}
                    {staffingAvailableGreaterThanPresent?<Typography className={classes.errorMessage}>Available Labor Pool must be less than Present</Typography>:undefined}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">2. Total Patient Count : {totalPatientCount}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">3. Available Beds</Typography>
                    <CreateAvailableBedsTable
                        bedsNotApplicable={bedsNotApplicable}
                        bedTypes={bedTypes}
                        bedStatuses={bedStatuses}
                        bedAvailability={bedAvailability}
                        setBedAvailability={setBedAvailability}
                    />
                    {bedPatientAwaitGreaterThanPresent?<Typography className={classes.errorMessage}>Awaiting Discharge must be less than Patients Present</Typography>:undefined}
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                {orgSettings.TRAIN?
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="h5">4. Triage Resource Allocation for Inpatients</Typography>
                            <CreateTRAINTable
                                trainNotApplicable={trainNotApplicable}
                                TRAINTypes={TRAINDetails.types}
                                TRAINCategories={TRAINDetails.categories}
                                TRAINData={TRAINData}
                                setTRAINData={setTRAINData}
                            />
                        </Grid>
                        <Grid item className={classes.spacing} xs={12}></Grid>
                    </React.Fragment>:undefined
                }
                <Grid item xs={12}>
                    <Typography variant="h5">{getSectionNumber(4)}. Technology Status</Typography>
                    <CreateTechnologyStatusTable
                        technologyItems={technologyItems}
                        technologyStatuses={technologyStatuses}
                        technologyItemStatus={technologyItemStatus}
                        setTechnologyItemStatus={setTechnologyItemStatus}
                    />
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{getSectionNumber(5)}. Resource Status</Typography>
                    <CreateResourceStatusTable
                        resourceItems={resourceItems}
                        resourceItemStatus={resourceItemStatus}
                        setResourceItemStatus={setResourceItemStatus}
                    />
                    {resourceAvailableGreaterThanOnHand?<Typography className={classes.errorMessage}>Available for Deployment must be less than On Hand</Typography>:undefined}
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Typography variant="h5">{getSectionNumber(6)}. Event Impact&nbsp;</Typography>
                        <input type="checkbox" {...noImpact}/>
                        <Typography variant="body2">No Impact</Typography>
                        <Tooltip title="Show impact of the event on your unit/department (e.g., short-staffed; smoke-in hall; patient capacity)">
                            <InfoIcon color="disabled" style={{marginLeft:10}}/>
                        </Tooltip>
                    </Grid>
                    <CustomizedTextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        rowsMax={10}
                        {...eventImpactText}
                    />
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center">
                        <Typography variant="h5">{getSectionNumber(7)}. Problems/Progress&nbsp;</Typography>
                        <input type="checkbox" {...noProblems}/>
                        <Typography variant="body2">No Problems</Typography>
                        <Tooltip title="Show problems or progress in managing the event by your unit/department (e.g., need more stretchers; staff needs relief; cleanup completed)">
                            <InfoIcon color="disabled" style={{marginLeft:10}}/>
                        </Tooltip>
                    </Grid>
                    <CustomizedTextField
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        rowsMax={10}
                        {...problemsProgressText}
                    />
                    <Grid item container alignItems='center'>
                        {submittingReport?
                            <CircularProgress color="secondary"/>:
                            <Button className={classes.button} color="secondary" variant="contained" size="small"
                                onClick={()=>submitReport()}
                            >
                                Submit Report
                            </Button>
                        }
                        {noEvent?<Typography className={classes.submitErrorMessage}>There is no event to submit to.</Typography>:undefined}
                        {invalidReport?<Typography className={classes.submitErrorMessage}>Please fix errors above.</Typography>:undefined}
                    </Grid>
                </Grid>
                <Grid item className={classes.spacing} xs={12}></Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(CreateStatusReportPage);