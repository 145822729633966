import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, FormControl } from '@material-ui/core'

const styles = theme => ({
    tableHeaderCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
    },
    tableHeaderRow: {
        height: 30,
    },
    tableCell: {
        width: "33%",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    input: {
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
    },
    select: {
        color: "#555555",
        fontSize: 14,
    },
    otherTitleInput: {
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
        color: "#555555",
        fontSize: 14,
        marginTop: 10,
    },
    otherTitleCell: {
        width: "33%",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
        height: 70,
    },
    formControl: {
        minHeight: 20,
    }
})
const CreateStaffingTable = React.memo((props)=>{
    const { classes, staffingEntries, staffTitles, setStaffingEntries } = props
    function handleDataChange(index,field){
        return function(event){
            let newArr = staffingEntries.slice(0);
            var newVal = parseInt(event.target.value);
            newArr[index][field] = isNaN(newVal)?"":newVal;
            setStaffingEntries(newArr);
        };
    }
    function handleOtherTitleChange(index){
        return function(event){
            let newArr = staffingEntries.slice(0);
            newArr[index]["otherTitle"] = event.target.value;
            setStaffingEntries(newArr);
        };
    }
    function selectOnFocus(event) {
        event.target.select();
    }
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    <TableCell className={classes.tableHeaderCell} align="center">Title</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Number Present</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">Available to Labor Pool (if needed)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {staffingEntries.map((entry, i) =>
                    <TableRow key={i}>
                        {/* Hard Coded title.id 14 = Other */}
                        <TableCell className={entry.title===14?classes.otherTitleCell:classes.tableCell} align="center">
                            <FormControl className={classes.formControl}>
                                <select
                                    className={classes.select}
                                    name="input"
                                    value={entry.title}
                                    onChange={handleDataChange(i,"title")}
                                >
                                    <option className={classes.option} value={-1}>Select Title</option>
                                    {staffTitles.map(title =>
                                        <option key={title.id} className={classes.option} value={title.id}>
                                            {title.name}
                                        </option>)
                                    }
                                </select>
                            </FormControl>
                            {//Hard Coded title.id 14 = Other
                                entry.title===14?
                                <input className={classes.otherTitleInput} type="text" 
                                    value={entry.otherTitle}
                                    onChange={handleOtherTitleChange(i)}
                                />:undefined
                            }
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <input className={classes.input}
                                type="number"
                                min={0}
                                value={entry.present}
                                onChange={handleDataChange(i,"present")}
                                onFocus={selectOnFocus}
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <input className={classes.input}
                                type="number"
                                min={0}
                                value={entry.available}
                                onChange={handleDataChange(i,"available")}
                                onFocus={selectOnFocus}
                            />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
})

export default withStyles(styles)(CreateStaffingTable);