import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import StaffingTable from './StaffingTable';
import AvailableBedsTable from './AvailableBedsTable';
import TechnologyStatusTable from './TechnologyStatusTable';
import ResourceStatusTable from './ResourceStatusTable';
import { format, parseISO } from 'date-fns'
import TRAINTable from './TRAINTable';
import CreateStatusReportPage from '../CreateStatusReportPage/CreateStatusReportPage';

const styles = theme => ({
    gridContainer: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        width: "100%"
    },
    companyLogo: {
        backgroundColor: theme.palette.background.default,
        maxWidth: "100%",
    },
    spacing: {
        height: 25,
    },
    title: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 25,
        fontWeight: "bold",
        textDecoration: "underline",
    },
    departmentInfoBox: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 2,
    },
    urgentNeedBox: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "red",
        padding: 8,
    },
    input: {
        textAlign: "center",
        paddingTop: 2,
        paddingBottom: 2,
    },
    bold: {
        fontWeight: "bold",
    },
    normal: {
        fontWeight: "normal",
    },
});

function ReportDetails(props) {
    const { classes, reportDetails, logo, orgSettings, history } = props;
    const [ updating, setUpdating ] = useState(false);
    function getSectionNumber(num){
        let sectionNum = num;
        if(reportDetails.TRAINData){
            if(num >= 4){
                sectionNum += 1;
            }
        }
        return sectionNum;
    }
    if(updating){
        return (
            <Grid container justify='center'>
                <Grid container justify='flex-end' className={classes.gridContainer}>
                    <Button className={classes.button} color="secondary" variant="contained" size="small"
                        onClick={()=>setUpdating(false)} 
                    >
                        Cancel Update
                    </Button>
                </Grid>
                <CreateStatusReportPage
                    departmentInfo={{
                        hospitalId: reportDetails.hospitalId,
                        departmentId: reportDetails.departmentId,
                        labels: reportDetails.departmentLabels,
                    }}
                    setDepartmentInfo={(e)=>{}}
                    logo={logo}
                    orgSettings={orgSettings}
                    initialDetails = {reportDetails}
                    history={history}
                />
            </Grid>
        )
    }
    return (
        <Grid container justify='center' className={classes.gridContainer}>
            <Grid item md={4}>
                <img className={classes.companyLogo}
                    src={logo}
                    alt=''
                />
            </Grid>
            <Grid container item md={4} direction="column" justify='center' alignItems='center'>
                <Typography className={classes.title} variant="h3">Report Details</Typography>
            </Grid>
            <Grid item md={4}>
                {reportDetails.updatable?
                    <Grid container justify='flex-end'>
                        <Button className={classes.button} color="secondary" variant="contained" size="small"
                            onClick={()=>setUpdating(true)} 
                        >
                            Update Report
                        </Button>
                    </Grid>:undefined
                }
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item sm={8}>
                <div className={classes.departmentInfoBox}>
                    <Typography style={{fontWeight:"bold",fontSize:16}}>{reportDetails.orgName}</Typography>
                    <br/>
                    <Typography>Comprehensive Emergency Management Plan</Typography>
                    {reportDetails.departmentLabels.map((n,i)=>{
                        if(i%2===0){
                            return(
                                <Grid container justify='space-between' key={i}>
                                    <Typography style={{fontWeight:"bold"}}>{n}</Typography>
                                    {<Typography style={{fontWeight:"bold"}}>{reportDetails.departmentLabels[i+1]?reportDetails.departmentLabels[i+1]:''}</Typography>}
                                </Grid>
                            )
                        }
                    })}
                </div>
            </Grid>
            <Grid item sm={2}/>
            <Grid item sm={2}>
                <div className={classes.urgentNeedBox}>
                    <Typography style={{fontSize:20}}>
                        <input type="checkbox" checked={reportDetails.urgentNeed} readOnly/>
                        Urgent Need
                    </Typography>
                    <Typography style={{fontSize: 12}}>(Check for Life Safety Issue)</Typography>
                </div>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Typography variant="h5">1. Staffing</Typography>
                <StaffingTable 
                    staffing={reportDetails.staffing} 
                />
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Typography variant="h5">2. Total Patient Count : {reportDetails.totalPatientCount}</Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Typography variant="h5">3. Available Beds</Typography>
                {reportDetails.bedsNotApplicable?
                    <Typography>N/A</Typography>:
                    <AvailableBedsTable
                        bedsNotApplicable={reportDetails.bedsNotApplicable}
                        bedTypes={reportDetails.bedTypes}
                        bedStatuses={reportDetails.bedStatuses}
                        bedAvailability={reportDetails.bedAvailability}
                    />
                }
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            {reportDetails.TRAINData?
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="h5">4. Triage Resource Allocation for Inpatients</Typography>
                        {reportDetails.trainNotApplicable?
                            <Typography>N/A</Typography>:
                            <TRAINTable
                                TRAINTypes={reportDetails.TRAINTypes}
                                TRAINCategories={reportDetails.TRAINCategories}
                                TRAINData={reportDetails.TRAINData}
                            />
                        }
                    </Grid>
                    <Grid item className={classes.spacing} xs={12}></Grid>
                </React.Fragment>:undefined
            }
            <Grid item xs={12}>
                <Typography variant="h5">{getSectionNumber(4)}. Technology Status</Typography>
                <TechnologyStatusTable
                    technologyItems={reportDetails.technologyItems}
                    technologyItemStatus={reportDetails.technologyItemStatus}
                />
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Typography variant="h5">{getSectionNumber(5)}. Resource Status</Typography>
                <ResourceStatusTable
                    resourceItems={reportDetails.resourceItems}
                    resourceItemStatus={reportDetails.resourceItemStatus}
                />
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                    <Typography variant="h5">{getSectionNumber(6)}. Event Impact&nbsp;</Typography>
                    <input type="checkbox" checked={reportDetails.noImpact} readOnly/>
                    <Typography variant="body2">No Impact</Typography>
                </Grid>
                <Typography>{reportDetails.eventImpactText}</Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                    <Typography variant="h5">{getSectionNumber(7)}. Problems/Progress&nbsp;</Typography>
                    <input type="checkbox" checked={reportDetails.noProblems} readOnly/>
                    <Typography variant="body2">No Problems</Typography>
                </Grid>
                <Typography>{reportDetails.problemsProgressText}</Typography>

            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    Submitted By :&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {reportDetails.user}&nbsp; 
                </Typography>
                <Typography className={classes.bold} variant="h6">
                    on&nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {format(parseISO(reportDetails.date),'MMM dd, yyyy hh:mm a')}&nbsp;
                </Typography>
            </Grid>
            <Grid container item xs={12} justify='flex-start'>
                <Typography className={classes.bold} variant="h6">
                    For Event: &nbsp;
                </Typography>
                <Typography className={classes.normal} variant="h6">
                    {reportDetails.eventName}
                </Typography>
            </Grid>
            <Grid item className={classes.spacing} xs={12}></Grid>
        </Grid>
    )
}

export default withStyles(styles)(ReportDetails);