import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
});

const ResourceBarChart = React.memo((props)=>{
    const { classes, colors, resourceItems } = props
    const labels = ["Total On Hand","Available to Deploy"];
    const chartData = {
      labels: resourceItems.map(n=>n.name),
      datasets: [{
        label: labels[0],
        backgroundColor: colors[0],
        data: resourceItems.map(n=>n.onHand),
      },{
        label: labels[1],
        backgroundColor: colors[1],
        data: resourceItems.map(n=>n.availableForDeployment),
      }]
    }
    const options = {
        // responsive: false,
        title: {
          display: true,
          text: "Resource",
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false
                }   
            }]
        },
        tooltips: {
            mode : 'label',
        },
    };
    return(
        <Bar
        //   width={380}
          height={200}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(ResourceBarChart);