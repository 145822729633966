import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  disabled: {},
  focused: {},
  error: {},
  notchedOutline: {},
  multiline: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  input: {
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const CustomizedTextField = React.memo((props)=>{
  const passProps = (({ 
    className,
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    label,
    margin,
    multiline,
    name,
    onChange,
    placeholder,
    required,
    rows,
    rowsMax,
    type,
    value,
    variant,
  }) => ({ className, autoComplete, autoFocus, defaultValue, disabled, error, FormHelperTextProps, fullWidth, helperText, id, label,
    margin, multiline, name, onChange, placeholder, required, rows, rowsMax, type, value, variant }))(props);
  return (
    <TextField
      {...passProps}
      InputProps={{ classes: props.classes, startAdornment: props.startAdornment, endAdornment: props.endAdornment }}
      id="custom-css-outlined-input"
    />
  );
})

export default withStyles(styles)(CustomizedTextField);