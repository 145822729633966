import * as React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Button from '@material-ui/core/Button'
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks'

const styles = theme => ({
    button: {
        color: "white",
        '&:hover': {
            color: theme.palette.secondary.main,
        },       
    },
});

const ParentPopupState = React.createContext(null)

const CascadingHoverMenus = (props) => {
    const { classes } = props;
    const popupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' })
    const strikePopupState = usePopupState({ popupId: 'demoMenu', variant: 'popover' })
    return (
        <div>
        <Button className={classes.button} color="secondary" size="large" {...bindHover(popupState)}>
            StatRep
        </Button>
        <ParentPopupState.Provider value={popupState}>
            <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            getContentAnchorEl={null}
            >
            <MenuItem component={Link} to="/event" onClick={popupState.close}>Dashboard</MenuItem>
            <MenuItem component={Link} to="/eventsSummary" onClick={popupState.close}>Events</MenuItem>
            <MenuItem component={Link} to="/report-list" onClick={popupState.close}>Reports</MenuItem>
            {/* <Submenu popupId="moreChoicesMenu" title="More Choices">
                <MenuItem onClick={popupState.close}>Cheesecake</MenuItem>
                <MenuItem onClick={popupState.close}>Cheesedeath</MenuItem>
                <Submenu popupId="evenMoreChoicesMenu" title="Even More Choices">
                <MenuItem onClick={popupState.close}>Cake (the band)</MenuItem>
                <MenuItem onClick={popupState.close}>Death Metal</MenuItem>
                </Submenu>
                <Submenu popupId="moreBenignChoices" title="More Benign Choices">
                <MenuItem onClick={popupState.close}>Salad</MenuItem>
                <MenuItem onClick={popupState.close}>Lobotomy</MenuItem>
                </Submenu>
            </Submenu> */}
            </Menu>
        </ParentPopupState.Provider>
        <Button className={classes.button} color="secondary" size="large" {...bindHover(strikePopupState)}>
            Strike
        </Button>
        <ParentPopupState.Provider value={strikePopupState}>
            <Menu
            {...bindMenu(strikePopupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            getContentAnchorEl={null}
            >
            <MenuItem component={Link} to="/strikeDashboard" onClick={strikePopupState.close}>Dashboard</MenuItem>
            <MenuItem component={Link} to="/strikeEvents" onClick={strikePopupState.close}>Events</MenuItem>
            <MenuItem component={Link} to="/strikeReports" onClick={strikePopupState.close}>Reports</MenuItem>
            </Menu>
        </ParentPopupState.Provider>
        </div>
    )
}

export default withStyles(styles)(CascadingHoverMenus);

const submenuStyles = theme => ({
  menu: {
    top: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
})

const Submenu = withStyles(submenuStyles)(
  // Unfortunately, MUI <Menu> injects refs into its children, which causes a
  // warning in some cases unless we use forwardRef here.
  React.forwardRef(({ classes, title, popupId, children, ...props }, ref) => {
    const parentPopupState = React.useContext(ParentPopupState)
    const popupState = usePopupState({
      popupId,
      variant: 'popover',
      parentPopupState,
    })
    return (
      <ParentPopupState.Provider value={popupState}>
        <MenuItem
          {...bindHover(popupState)}
          selected={popupState.isOpen}
          ref={ref}
        >
          <ListItemText className={classes.title}>{title}</ListItemText>
          <ChevronRight className={classes.moreArrow} />
        </MenuItem>
        <Menu
          {...bindMenu(popupState)}
          className={classes.menu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          {...props}
        >
          {children}
        </Menu>
      </ParentPopupState.Provider>
    )
  })
)