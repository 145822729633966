import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogContent, DialogActions, Typography, DialogTitle, FormControl, Grid, CircularProgress } from '@material-ui/core'
import axios from 'axios';
import { handleAxiosError, getAPIUrl } from './AppUtils';
import { useFormInput } from './CustomHooks';
import CustomizedTextField from './CustomizedTextField';

const styles = theme => ({
    select: {
        color: "#555555",
        fontSize: 16,
    },
})

function EditEventDialog(props){
    const { classes, open, event, handleClose, refreshEvents } = props;
    const source = axios.CancelToken.source();
    const eventName = useFormInput("");
    const status = useFormInput(0);
    const [saving, setSaving] = useState(false);

    useEffect(()=>{
        if(event){
            eventName.onChange({target:{value:event.name}});
            status.onChange({target:{value:event.status}});
        }
    },[event])

    function saveEvent(){
        setSaving(true);
        axios.post(getAPIUrl()+'/editEvent',{name:eventName.value, status: status.value, id: event.id})
        .then(response => {
            refreshEvents();
            handleClose();
        })
        .catch(error=>{
            handleAxiosError(error)
            refreshEvents();
        })
    }
    function handleExited(){
        setSaving(false);
    }

    return (
        <div>
            <Dialog 
                open={open}
                onClose={handleClose}
                fullWidth
                onExited={handleExited}
            >
                <DialogTitle>Edit Strike Event</DialogTitle>
                <DialogContent>
                    <Grid style={{marginBottom:20}} container direction="row" alignItems="center">
                        <Typography style={{marginRight:20}}>Event Name: </Typography>
                        <CustomizedTextField
                            className={classes.textField}
                            variant="outlined"
                            {...eventName}
                            autoFocus
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                        <Typography style={{marginRight:20}}>Status: </Typography>
                        <FormControl className={classes.formControl}>
                            <select
                                className={classes.select}
                                {...status}
                            >
                                <option value={1}>Open</option>
                                <option value={0}>Closed</option>
                            </select>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {saving?
                        <CircularProgress size={30} color="secondary" style={{marginRight: 15}}/>:
                        <Button className={classes.button} onClick={saveEvent} variant="contained" color="secondary">
                            Save
                        </Button>
                    }
                    <Button className={classes.button} onClick={handleClose} variant="contained" color="default">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default withStyles(styles)(EditEventDialog);