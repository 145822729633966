import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Pie } from 'react-chartjs-2';

const styles = theme => ({
});

const StaffingPieChart = React.memo((props)=>{
    const { classes, title, labels, data, colors } = props
    const chartData = {
      labels: labels,
      datasets: [{
        backgroundColor: colors,
        data: data
      }]
    }
    const options = {
        responsive: false,
        title: {
          display: true,
          text: title,
          fontSize: 14,
          fontColor: '#191F2D',
        //   fontStyle: 'normal',
        },
        legend: {
          display: true,
          position: 'bottom',
          labels:{boxWidth:20, fontSize:10 } 
        }
    };
    return(
        <Pie
          width={380}
          height={320}
          data={chartData}
          options={options}
        />
    )

})

export default withStyles(styles)(StaffingPieChart);